import {
    CartDispatcher as SourceCartDispatcher,
    CURRENT_WEBSITE
} from 'SourceStore/Cart/Cart.dispatcher';
import { getCartId } from 'Util/Cart';
// import EstimateShippingQuery from 'Query/EstimateShipping.query';
import CheckoutQuery from 'Query/Checkout.query';
import CartQuery from 'Query/Cart.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { updateTotals, updateEstimateShipping } from 'Store/Cart/Cart.action';

export {
    CURRENT_WEBSITE
};

/** @namespace KaegiScandipwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async _updateCartData(cartData, dispatch) {
        dispatch(updateTotals(cartData));
        if (Object.keys(cartData).length > 0) {
            const guestCartId = getCartId();
            // const data = await fetchMutation(EstimateShippingQuery.getQuery(guestCartId));
            const {
                estimateShippingCosts
            } = await fetchMutation(CheckoutQuery.getEstimateShippingCosts({ country_id: 'CH' }, guestCartId));

            dispatch(updateEstimateShipping(estimateShippingCosts));
        }
    }

    async addPrintessDataToCartItem(dispatch, options) {
        try {
            const {
                cartId,
                saveToken,
                thumbnailUrl,
                sku,
                noUpdate = false
            } = options;
            const usedCartId = cartId || getCartId();
            // eslint-disable-next-line max-len
            await fetchMutation(CartQuery.getAddPrintessDataToCartItemMutation(usedCartId, saveToken, thumbnailUrl, sku));

            if (!noUpdate) {
                await this.updateInitialCartData(dispatch);
            }
        } catch (error) {
            return Promise.reject();
        }

        return Promise.resolve();
    }

    async addProductToCart(dispatch, options = {}) {
        const { products = [], cartId: userCartId, noUpdate = false } = options;

        const cartId = userCartId || getCartId();

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification('error', __('No product data!')));

            return Promise.reject();
        }

        try {
            if (!cartId) {
                return Promise.reject();
            }

            const { addProductsToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, products)
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(showNotification('error', getErrorMessage(error)));
                });

                return Promise.reject();
            }

            if (!noUpdate) {
                await this.updateInitialCartData(dispatch);
            }
            dispatch(showNotification('success', __('Product was added to cart!')));
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(showNotification('error', __('Not possible to fetch while offline')));

                return Promise.reject();
            }

            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }

        return Promise.resolve();
    }
}

export default new CartDispatcher();
