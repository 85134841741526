import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/**
 * Order Query
 * @class OrderQuery
 * @namespace KaegiScandipwa/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    _getCartItemsFields() {
        return [
            ...super._getCartItemsFields(),
            'printess_thumbnail_url',
            'printess_save_token'
        ];
    }

    getAddPrintessDataToCartItemMutation(cartId, saveToken, thumbnailUrl, sku) {
        return new Field('addPrintessDataToCartItem')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('save_token', 'String!', saveToken)
            .addArgument('thumbnail_url', 'String!', thumbnailUrl)
            .addArgument('sku', 'String!', sku)
            .addField(this._getCartUpdateField());
    }
}

export default new CartQuery();
